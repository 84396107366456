import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import overwhelmedImage from "../images/overwhelmed.jpg";

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <section className="flex flex-col md:flex-row items-center">
        <div className="md:w-2/3 md:mr-8">
          <blockquote className="border-l-4 border-gray-900 font-serif leading-loose pl-4 text-justify">
            Upper Airway Resistance Syndrome, or UARS, is a subtle sleep disorder that can make it tough to live your life to the fullest.
          </blockquote>

          <cite className="font-bold mt-4 text-right text-xs uppercase block">
            – UARSHelp.com
          </cite>
        </div>

        <figure className="w-2/3 md:w-1/3 rounded-lg">
          <img src={overwhelmedImage} alt="A dog relaxing" className="rounded-lg" />
        </figure>
      </section>
    </Layout>
  );
}

export default AboutPage;
